// This is a map from champion id to korean names that Neil made.
const KOREAN_NAMES = {
    "Aatrox": "다르킨의 검",
    "Ahri": "구미호",
    "Akali": "섬기는 이 없는 암살자",
    "Alistar": "미노타우로스",
    "Amumu": "슬픈 미라",
    "Anivia": "얼음불사조",
    "Annie": "어둠의 아이",
    "Aphelios": "신념의 무기",
    "Ashe": "서리 궁수",
    "AurelionSol": "별의 창조자",
    "Azir": "사막의 황제",
    "Bard": "영겁의 수호자",
    "Blitzcrank": "거대 증기 골렘",
    "Brand": "타오르는 복수",
    "Braum": "프렐요드의 심장",
    "Caitlyn": "필트오버의 보안관",
    "Camille": "강철의 그림자",
    "Cassiopeia": "독사의 포옹",
    "Chogath": "공허의 공포",
    "Corki": "대담한 폭격수",
    "Darius": "녹서스의 실력자",
    "Diana": "차가운 달의 분노",
    "Draven": "화려한 처형자",
    "DrMundo": "자운의 광인",
    "Ekko": "시간을 달리는 소년",
    "Elise": "거미 여왕",
    "Evelynn": "고통스런 포옹",
    "Ezreal": "무모한 탐험가",
    "Fiddlesticks": "오래된 공포",
    "Fiora": "결투의 대가",
    "Fizz": "대양의 말썽꾸러기",
    "Galio": "위대한 석상",
    "Gangplank": "바다의 무법자",
    "Garen": "데마시아의 힘",
    "Gnar": "잃어버린 고리",
    "Gragas": "술취한 난동꾼",
    "Graves": "무법자",
    "Hecarim": "전쟁의 전조",
    "Heimerdinger": "위대한 발명가",
    "Illaoi": "크라켄의 여사제",
    "Irelia": "칼날 무희",
    "Ivern": "자연의 아버지",
    "Janna": "폭풍의 분노",
    "JarvanIV": "데마시아의 귀감",
    "Jax": "무기의 달인",
    "Jayce": "미래의 수호자",
    "Jhin": "잔혹극의 거장",
    "Jinx": "난폭한 말괄량이",
    "Kaisa": "공허의 딸",
    "Kalista": "복수의 화신",
    "Karma": "깨우친 자",
    "Karthus": "죽음을 노래하는 자",
    "Kassadin": "공허의 방랑자",
    "Katarina": "사악한 칼날",
    "Kayle": "정의로운 자",
    "Kayn": "그림자 사신",
    "Kennen": "폭풍의 심장",
    "Khazix": "공허의 약탈자",
    "Kindred": "영겁의 사냥꾼",
    "Kled": "망나니 기사",
    "KogMaw": "심연의 아귀",
    "Leblanc": "환술사",
    "LeeSin": "눈먼 수도승",
    "Leona": "여명의 빛",
    "Lillia": "수줍은 꽃",
    "Lissandra": "얼음 마녀",
    "Lucian": "정화의 사도",
    "Lulu": "요정 마법사",
    "Lux": "광명의 소녀",
    "Malphite": "거석의 파편",
    "Malzahar": "공허의 예언자",
    "Maokai": "뒤틀린 나무 정령",
    "MasterYi": "우주 검사",
    "MissFortune": "현상금 사냥꾼",
    "MonkeyKing": "원숭이 왕",
    "Mordekaiser": "강철의 망령",
    "Morgana": "타락한 자",
    "Nami": "파도 소환사",
    "Nasus": "사막의 관리자",
    "Nautilus": "심해의 타이탄",
    "Neeko": "알쏭달쏭 카멜레온",
    "Nidalee": "야성의 사냥꾼",
    "Nocturne": "영원한 악몽",
    "Nunu": "소년과 설인",
    "Olaf": "광전사",
    "Orianna": "시계태엽 소녀",
    "Ornn": "거산의 화염",
    "Pantheon": "불굴의 창",
    "Poppy": "망치의 수호자",
    "Pyke": "핏빛 항구의 학살자",
    "Qiyana": "원소의 여왕",
    "Quinn": "데마시아의 날개",
    "Rakan": "매혹하는 자",
    "Rammus": "중무장 아르마딜로",
    "RekSai": "공허의 복병",
    "Rell": "철의 여인",
    "Renekton": "사막의 도살자",
    "Rengar": "추적하는 사자",
    "Riven": "추방자",
    "Rumble": "기계 악동",
    "Ryze": "룬 마법사",
    "Samira": "사막의 장미",
    "Sejuani": "혹한의 분노",
    "Senna": "구원자",
    "Seraphine": "노래하는 별",
    "Sett": "우두머리",
    "Shaco": "악마 어릿광대",
    "Shen": "황혼의 눈",
    "Shyvana": "하프 드래곤",
    "Singed": "미친 화학자",
    "Sion": "언데드 학살병기",
    "Sivir": "전장의 여제",
    "Skarner": "수정 선봉장",
    "Sona": "현의 명인",
    "Soraka": "별의 아이",
    "Swain": "녹서스 대장군",
    "Sylas": "해방된 자",
    "Syndra": "어둠의 여제",
    "TahmKench": "강의 폭군",
    "Taliyah": "바위술사",
    "Talon": "검의 그림자",
    "Taric": "발로란의 방패",
    "Teemo": "날쌘 정찰병",
    "Thresh": "지옥의 간수",
    "Tristana": "요들 사수",
    "Trundle": "트롤 왕",
    "Tryndamere": "야만전사 왕",
    "TwistedFate": "카드의 달인",
    "Twitch": "역병 쥐",
    "Udyr": "정령 주술사",
    "Urgot": "살상 병기",
    "Varus": "응징의 화살",
    "Vayne": "어둠 사냥꾼",
    "Veigar": "악의 작은 지배자",
    "Velkoz": "공허의 눈",
    "Vi": "필트오버의 집행자",
    "Viego": "몰락한 왕",
    "Viktor": "기계화의 전령관",
    "Vladimir": "진홍빛 사신",
    "Volibear": "무자비한 폭풍",
    "Warwick": "자운의 고삐 풀린 분노",
    "Xayah": "저항하는 자",
    "Xerath": "초월한 마법사",
    "XinZhao": "데마시아의 호위무사",
    "Yasuo": "용서받지 못한 자",
    "Yone": "잊히지 못한 자",
    "Yorick": "영혼의 길잡이",
    "Yuumi": "마법 고양이",
    "Zac": "비밀 병기",
    "Zed": "그림자의 주인",
    "Ziggs": "마법공학 폭파병",
    "Zilean": "시간의 수호자",
    "Zoe": "여명의 성위",
    "Zyra": "가시 덩굴의 복수"
};

export default KOREAN_NAMES;
