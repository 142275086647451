import VueRouter from 'vue-router';

import StratsPage from './components/Strats';
import UserPage from './components/User';
import FullPageSearch from './components/FullPageSearch';

const routes = [
    { path: '/user', name: 'user', component: UserPage },
    { path: '/:m/:c/:sIdx/:prIdx/:puIdx', name: 'strat', component: StratsPage },
    { path: '/', name: 'root', component: FullPageSearch},
    { path: '*', redirect: '/'},
];

const router = new VueRouter({
    routes,
})

export default router;
