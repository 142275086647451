<template>
  <div class="wrapper">
    <div class="header-row">
      <div class="header-background">
        <div class="s-curve-half blue" />
        <ss-header-s-curve class="s-curve" />
        <div class="s-curve-half red" />
      </div>
      <div class="with-max">
        <a-row type="flex" justify="center" align="middle">
          <a-col :xs="12">
            <router-link to="/" class="logo" />
          </a-col>
          <a-col :xs="12">
            <span class="user-area">
                <a-dropdown v-if="this.user" :trigger="['click']">
                  <a-button class="user-button" type="link">
                      {{ this.user.attributes.email }} <a-icon type="down" />
                  </a-button>
                  <a-menu slot="overlay" @click="onUserMenuClick">
                      <a-menu-item key="myBuilds">
                        <router-link to="/user" class="user-link"> Your builds </router-link>
                      </a-menu-item>
                      <a-menu-divider />
                      <a-menu-item key="logout">Log out</a-menu-item>
                  </a-menu>
                </a-dropdown>
                <a-button v-else @click="login" class="user-button" type="link">Log in</a-button>
            </span>
          </a-col>
        </a-row>
      </div>
    </div>
    <div class="body-content with-max full-height">
      <slot />
    </div>
  </div>
</template>

<script>
import HeaderSCurve from './HeaderSCurve';
import { Auth, Hub } from "aws-amplify";

export default {
  name: "PageContainer",
  components: {
    'ss-header-s-curve': HeaderSCurve,
  },
  methods: {
    login: function () {
      Auth.federatedSignIn({ provider: "Google" });
    },
    logout: function () {
      Auth.signOut();
    },
    getUser: function () {
      return Auth.currentAuthenticatedUser()
        .then(user => user)
        .catch(err => console.log(err));
    },
    onUserMenuClick: function (action) {
        switch(action.key) {
            case "logout":
                this.logout();
                break;
        }
    },
  },
  mounted() {
    Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "signIn":
          this.user = data;
          break;
        case "signOut":
          this.user = null;
          break;
      }
    });
    Auth.currentAuthenticatedUser()
        .then(user => this.user = user)
        .catch(err => console.log(err));
  },
  data() {
    return { user: null };
  },
};
</script>

<style lang="scss">
// Update HeaderSCurve.vue if you update these
$red: #ae0a1d;
$blue: #004992;
$headerHeight: 60px;

.with-max {
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
}

.wrapper {
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  flex-direction: column;
  position: relative;
}

.body-content {
  flex-grow: 1;
  flex-shrink: 1;
  z-index: 1;
  overflow: hidden;
  padding: 20px 10px;
}

.header-row {
  flex-grow: 0;
  flex-shrink: 0;
  padding: 10px;
}

.logo {
  background-image: url("../assets/secret-strats-icon.png");
  height: 2.5rem;
  width: 2.5rem;
  background-repeat: no-repeat;
  background-size: 100%;
  display: block;
}

.user-area {
    float: right;
}

.user-button {
    color: #fff!important;
}

.user-button:hover {
    color: #ccc!important;
}

.s-curve {
    height: $headerHeight;
    overflow: visible!important;
}

.s-curve-half {
    width: 100%;
}

.blue {
    background: $blue;
}

.red {
    background: $red;
}

.header-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: $headerHeight;
    display: flex;
}

.full-height {
    height: 100%!important;
}

.full-width {
    width: 100%!important;
}
</style>
