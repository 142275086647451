<template>
    <ss-page-container :title="'Your builds'">
        <div class="saved">
            <h2 class="centered-header">Saved</h2>
            <a-table
                :columns="COLUMNS"
                :data-source="savedData"
                :show-header="false"
                :pagination="false"
                :row-key="keyGenerator"
                class="history-table"
            >
                <span slot="strat" slot-scope="text, record">
                    <router-link :to="{ name: 'strat', params: record }">
                        {{ record.m }} {{ record.c }}
                    </router-link>
                </span>
                <span slot="remove" slot-scope="text, record">
                    <a-button type="link" icon="close" @click="deleteSaved(record)" />
                </span>
            </a-table>
        </div>
        <div>
            <h2 class="centered-header">History</h2>
            <a-table
                :columns="COLUMNS"
                :data-source="historyData"
                :show-header="false"
                :pagination="false"
                :row-key="keyGenerator"
                class="history-table"
            >
                <span slot="strat" slot-scope="text, record">
                    <router-link :to="{ name: 'strat', params: record }">
                        {{ record.m }} {{ record.c }}
                    </router-link>
                </span>
                <span slot="save" slot-scope="text, record">
                    <a-button type="link" @click="saveHistoryStrat(record)">Save build</a-button>
                </span>
                <span slot="remove" slot-scope="text, record">
                    <a-button type="link" icon="close" @click="deleteHistory(record)" />
                </span>
            </a-table>
        </div>
    </ss-page-container>
</template>

<script>
import PageContainer from './PageContainer';
import HistoryService from '../services/history';

const COLUMNS = [
    {
        scopedSlots: { customRender: 'strat' }
    },
    {
        dataIndex: 'save',
        scopedSlots: { customRender: 'save' },
        width: '100px',
    },
    {
        dataIndex: 'rm',
        scopedSlots: { customRender: 'remove' },
        width: '50px'
    }
];

export default {
  name: 'User',
  components: {
    'ss-page-container': PageContainer,
  },
  data() {
    return {
        COLUMNS,
        historyData: HistoryService.getHistory(),
        savedData: HistoryService.getSaved(),
        keyGenerator: r => `${r.m}${r.c}`
    }
  },
  methods: {
    deleteSaved(strat) {
        HistoryService.deleteSaved(strat);

        this.savedData = HistoryService.getSaved();
    },
    deleteHistory(strat) {
        HistoryService.deleteHistory(strat);

        this.historyData = HistoryService.getHistory();
    },
    saveHistoryStrat(strat) {
        HistoryService.pushSaved(strat);

        this.savedData = HistoryService.getSaved();
        this.historyData = HistoryService.getHistory();
    }
  }
}
</script>

<style scoped>
.history-table {
    background: white;
    max-width: 800px;
    border-radius: 5px;
    margin: 0 auto 3rem auto;
}

.saved {
    margin-top: 2rem;
}

.centered-header {
    text-align: center;
}
</style>
