import {MYTHICS, MYTHICS_ALL} from '../data/mythics';
import {CHAMPIONS, CHAMPIONS_ALL} from '../data/champions';
import {PROVERBS, PUNCTUATIONS} from '../data/proverbs';
import SICKNESS from '../data/sickness';

class RandomRollService {
    static _randomIdx(items) {
        return Math.floor(Math.random() * items.length)
    }

    static _parseOptions(selectedItems, allArr, prefix, categorized) {
        let options = [];
        if (selectedItems.length === 0) {
            options = allArr;
        } else {
            for (let i = 0; i < selectedItems.length; i++) {
                const thisItem = selectedItems[i];
                if (prefix && thisItem.startsWith(`${prefix}_`)) {
                    options = options.concat(categorized[thisItem]);
                } else {
                    options = options.concat([thisItem]);
                }
            }
        }
        return options;
    }

    static rollProverbs() {
        const sicknessIdx = this._randomIdx(SICKNESS);
        const proverbIdx = this._randomIdx(PROVERBS);
        const punctuationIdx = this._randomIdx(PUNCTUATIONS);

        return [sicknessIdx, proverbIdx, punctuationIdx];
    }

    static rollMythic(selectedMythic) {
        const mythicOptions = this._parseOptions(selectedMythic, MYTHICS_ALL, 'MYTHICS', MYTHICS);
        const mythicIdx = this._randomIdx(mythicOptions);
        const mythic = mythicOptions[mythicIdx];

        return mythic;
    }

    static rollChampion(selectedChampion) {
        const championOptions = this._parseOptions(selectedChampion, CHAMPIONS_ALL, 'CHAMPIONS', CHAMPIONS);
        const championIdx = this._randomIdx(championOptions);
        const champion = championOptions[championIdx];

        return champion;
    }
}

export default RandomRollService;
