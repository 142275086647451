import Vue from 'vue'
import VueRouter from 'vue-router';
import {Input, Collapse, Icon, Button, Select, Row, Col, Table, Dropdown, Menu, Popover, Checkbox} from 'ant-design-vue';

import App from './App';
import router from './router';
import 'ant-design-vue/dist/antd.css';

Vue.config.productionTip = false
Vue.use(VueRouter);

Vue.use(Button);
Vue.use(Dropdown);
Vue.use(Menu);
Vue.use(Menu.Item);
Vue.use(Icon);
Vue.use(Select);
Vue.use(Row);
Vue.use(Col);
Vue.use(Table);
Vue.use(Popover);
Vue.use(Checkbox);
Vue.use(Checkbox.Group);
Vue.use(Collapse);
Vue.use(Collapse.Panel);
Vue.use(Input);

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
