const PROVERBS = [
    '제 눈에 안경이다',
    '꿩 먹고 알 먹는다',
    '로마는 하루아침에 이루어진 것이 아니다',
    '보기 좋은 떡이 먹기도 좋다',
    '과부 설움은 홀아비가 안다',
    '낮말은 새가 듣고 밤말은 쥐가 듣는다',
    '눈에서 멀어지면, 마음에서도 멀어진다',
    '말을 냇가에 끌고 갈 수는 있어도 억지로 물을 먹일 수는 없다',
    '백지장도 맞들면 낫다',
    '궁하면 통한다',
    "고생 끝에 낙이 온다",
    "개천에서 용 난다",
    "개똥도 약에 쓰려면 없다",
    "공자 앞에서 문자 쓴다",
    "누워서 떡 먹기",
    "등잔 밑이 어둡다",
    "김치국부터 마시지 말라",
    "가재는 게 편이라",
    "과부 설움은 홀아비가 안다",
    "옷이 날개다",
    "원숭이도 나무에서 떨어진다",
    "매도 먼저 맞는 게 낫다",
    "강도를 은행에 두지 마십시오",
    "결국 말하고 행해진 것보다 더 많은 말이 있습니다",
    "남자는 여자를 잡을 때까지 쫓는다",
    "말을 물로 이끌 수는 있지만 마시 게 할 수는 없습니다",
    "오늘 할 수있는 일을 내일로 미루지 마십시오",
    "책임자라면 깊이 생각해보십시오 문제가 있으면 위임하십시오 의심 스러우면 중얼 거린다",
    "기대하지 마십시오 검사하십시오",
    "둘은 회사이지만 셋은 군중입니다",
    "오래된 습관은 심하게 죽습니다",
    "처음 100 년이 가장 어렵습니다",
    "흥정하려면 두 가지가 필요합니다",
    "진실만큼 아픈 것은 없습니다",
    "손에 든 새는 덤불에서 두 마리의 가치가 있습니다",
    "태양이 비치는 동안 건초를 만드십시오",
    "그의 모카신에서 1 마일을 걸을 때까지 남자를 비난하지 마십시오",
    "표범은 그 자리를 바꿀 수 없습니다",
    "두 가지 악 중에서 가장 위선이 적은 사람에게 투표하는 것이 항상 최선입니다",
    "당신의 문제를 거의 혼자만 유지하는 것이 가장 좋습니다",
    "우정의 길에 풀이 자라지 않도록하십시오",
    "사람의 마음으로가는 길은 배를 통해서입니다",
    "한번의 좋은 결정은 다음번 결정도 좋게한다",
    "나쁜 소식은 빠르게 전달됩니다",
    "삶의 비결은 당신이 좋아하는 것을하는 것이 아니라 당신이하는 것을 좋아하는 것입니다 돈을 두 배로 늘리는 가장 빠른 방법은 돈을 접어서 주머니에 다시 넣는 것입니다",
    "눈으로 관찰하십시오 귀로 들어라 입 다물어",
    "마지막으로 웃는 사람은 가장 잘 웃는다",
    "족장이 너무 많고 인디언이 충분하지 않습니다",
    "자선은 집에서 시작됩니다",
    "작은 시작에서 큰 일이옵니다",
    "사과는 나무에서 멀리 떨어지지 않습니다",
    "머리로가는 길은 심장을 통해 있습니다",
    "필요성은 발명의 어머니입니다",
    "차라리 \"여기 그가 거짓말을 해요\"보다 \"저기 그가 간다\"라고 말하게하고 싶습니다",
    "돌아 다니는 것이 돌아옵니다",
    "개울을 건너는 동안 말을 바꾸지 마십시오",
    "비뚤어진 옥수수 대는 곧은 귀를 가질 수 있습니다",
    "짖는 개는 물지 않는다",
    "지옥과 법정은 항상 열려 있습니다",
    "급할수록 돌아 가라",
    "고백 된 잘못은 절반이 시정됩니다",
    "그렇지 않으면 혀가 귀머거리를 유지할 것입니다",
    "계란은 긁어 낼 수 없습니다",
    "무지가 많다고해서 무지가되어서는 안됩니다",
    "손실을 줄이고 수익을 올리십시오",
    "아름다움은 보는 사람의 눈에 있습니다",
    "우리가 도착하면 그 다리를 건너겠습니다",
    "모두 사랑과 골프에서 공평합니다",
    "우리는 조언을 할 수는 있지만 행동을 할 수는 없습니다",
    "의사의 잘못은 흙으로 덮여 있고 부자는 돈으로 덮여 있습니다",
    "도약하기 전에보세요",
    "법이 당신에게 위배되면 사실을 주장하십시오 사실이 당신에 반하는 경우 법을 주장하십시오 둘 다 당신을 반대하면 주제를 바꾸십시오",
    "모든 동물은 당신보다 더 많이 알고 있습니다",
    "숲 뒤에 비가옵니다",
    "많은 좋은 교수형은 나쁜 결혼을 예방합니다",
    "미리 경고합니다",
    "악에 대한 사랑은 모든 돈의 뿌리입니다",
    "흙을 던지면 땅을 잃습니다",
    "만세, 긴 사랑",
    "아름답고 똑똑하고 사랑스러운 사람이 당신의 삶에 올 것입니다",
    "모호한 친구는 위장의 적이 될 수 있습니다",
    "충실한 친구는 강력한 방어입니다",
    "손에있는 깃털이 공중에있는 새보다 낫습니다",
    "새로운 시작은 당신의 길을 안내 할 것입니다",
    "친구는 당신의 돈이 아니라 당신의 시간만을 요구합니다",
    "친구는 자신에게주는 선물입니다",
    "도박꾼은 자신이 가진 것을 잃을뿐만 아니라 가지고 있지 않은 것을 잃게됩니다",
    "이번 달에 기회의 황금 알이 무릎에 떨어집니다",
    "좋은 우정은 종종 열정적 인 로맨스보다 더 중요합니다",
    "오래된 작업을 마무리하기에 좋은 시간입니다",
    "직감은 무언가를 말하려는 창의력입니다",
    "곧 평생 친구가 될 것입니다",
    "평생의 행복이 당신 앞에 놓여 있습니다",
    "가벼운 마음은 모든 힘든시기를 헤쳐나갑니다",
    "새해에는 새로운 시각이옵니다",
    "사람은 배우기 위해 결코 늙지 않습니다",
    "말만하고 행동이 아닌 사람은 잡초가 가득한 정원과 같습니다",
    "즐거운 놀라움이 당신을 기다리고 있습니다",
    "짧은 연필은 일반적으로 긴 기억보다 낫습니다",
    "작은 기부가 필요합니다 옳은 일입니다",
    "미소는 당신의 개인적인 환영 매트입니다",
    "순조로운 긴 여정! 큰 기대",
    "부드러운 목소리는 매우 설득력이있을 수 있습니다",
    "진정으로 풍요로운 삶에는 사랑과 예술이 풍부합니다",
    "바꿀 수없는 것을 받아들이면 기분이 나아질 것입니다",
    "모험은 진정한 행복이 될 수 있습니다",
    "조언은 키스와 같습니다 비용이 들지 않으며 즐거운 일입니다",
    "가장 필요한 경우 조언은 최소한으로주의해야합니다",
    "당신이하는 모든 노력은 궁극적으로 성과를 거둘 것입니다",
    "당신이 가진 모든 문제는 매우 빨리 사라질 것입니다",
    "모든 것이 새 프로젝트와 잘 어울릴 것입니다",
    "당신의 모든 노력이 곧 성과를 거둘 것입니다",
    "연민이 당신의 결정을 인도하도록 허용하십시오",
    "과거를 아는 사람은 가까운 장래에 영향을 미칠 것입니다",
    "기분 좋은 로맨스가 등장하기 시작할 수 있습니다",
    "중요한 사람이 당신을 지원할 것입니다",
    "1 인치는 금 1 인치입니다",
    "내일 내려야 할 모든 결정은 좋은 결정입니다",
    "사랑의 손길에 모든 사람이 시인이됩니다",
    "조심하지 않으면 오늘 속임수에 빠질 수 있습니다",
    "다양한 형태의 아름다움이 당신에게 어필합니다",
    "당신이 자신에게 더 많은 것을 요구하기 때문에 다른 사람들은 당신을 깊이 존경합니다",
    "자신과 다른 사람들도 믿으십시오",
    "할 수 있다고 믿으십시오",
    "한 번 자신을 잃는 것보다 두 번 물어 보는 것이 좋습니다",
    "성공이 가까웠으니 시간을 잘 지키십시오",
    "대리석이 아닌 마음에 이름을 새기십시오",
    "당신의 삶에서 변화가 일어나고 있으므로 흐름을 따라 가십시오",
    "당신과 같은 능력은 과소 평가됩니다",
    "축하합니다! 당신은 가고 있습니다",
    "마음으로가는 길을 알려줄 수 있을까요",
    "예의는 가정에서 시작됩니다",
    "예의는 전염성이 있습니다",
    "호기심은 지루함을 없애줍니다 호기심을 죽일 수있는 것은 없습니다",
    "당면한 일에 차분한 마음으로 자신을 바치십시오",
    "운명이 당신에게 부여한 길에서 떠나지 마십시오",
    "결정은 지금 당신에게 필요한 것입니다",
    "근면과 겸손은 사회적 지위를 높일 수 있습니다",
    "불신은 마법을 파괴합니다",
    "헛된 것에서 벗어나십시오",
    "다른 사람의 웅변에 겁 먹지 마십시오",
    "이미 마음을 가졌다면 누군가의 영혼을 요구하지 마십시오",
    "야망이 작은 성공을 가리지 않도록하십시오",
    "자신을 위해 추가 작업을하지 마십시오",
    "자신을 과소 평가하지 마십시오 인간은 무한한 잠재력을 가지고 있습니다",
    "가장 바쁜 사람이 가장 많은 시간을 가지고 있다는 것을 알고 있습니까",
    "낙심하지 마십시오 모든 잘못된 시도를 버리는 것이 또 다른 단계이기 때문입니다",
    "무모함과 자신감을 혼동하지 마십시오",
    "시간 만 보내지 마십시오 투자하십시오",
    "생각 만하지 말고 행동하세요",
    "친구들이 당신에게 강요하지 않도록하고 조용하고 조용히 일하십시오",
    "과거와 쓸모없는 세부 사항이 당신의 존재를 질식시키지 마십시오",
    "당신의 한계가 당신의 재능을 가리지 않도록하십시오",
    "걱정하지 마세요 번영이 곧 문을 두 드릴 것입니다",
    "매일 자신이하고 싶지 않은 일을하도록 강요하십시오",
    "교육은 삶의 상황을 충족시키는 능력입니다",
    "당신이 가진이 사랑의 관계를 포용하세요",
    "부모님에게서 존경하는 것을 모방하십시오",
    "친구에게서 존중하는 것을 모방하십시오",
    "모든 꽃은 그 자신의 달콤한 시간에 피어납니다",
    "모든 현명한 사람은 많은 질문을하는 것으로 시작했습니다",
    "당신의 삶에서 매일은 특별한 날입니다",
    "어디를 가든 친절한 얼굴이 당신을 맞이합니다",
    "자신과 다른 사람들은 거의 기대하지 마십시오",
    "실패는 다음에 더 잘할 수있는 기회입니다",
    "실패는 임대 지속성의 경로입니다",
    "두려움과 욕망 – 동전의 양면",
    "두려움없는 용기가 승리의 기초입니다",
    "소에게 장미를 먹이는 것은 특별한 감사를 얻지 못합니다",
    "먼저하고 싶은 일을 생각하십시오 그런 다음해야 할 일을하십시오",
    "중간 경로를 따르십시오 어느 쪽도 당신을 행복하게 만들지 않습니다",
    "증오는 결코 증오로 정복되지 않습니다 증오는 사랑으로 정복됩니다",
    "우리가하기 전에 배워야하는 것은 그것을 행함으로써 배웁니다",
    "경청에서 지혜와 말에서 회개가 나옵니다",
    "이제부터 당신의 친절은 당신을 성공으로 이끌 것입니다",
    "마음을 정하십시오 – 자신감이 당신을 이끌 것입니다",
    "마음을 정하십시오… 자신감이 당신을 이끌 것입니다",
    "오늘 금메달을 찾으세요! 당신은 무엇이든 챔피언이 될 것입니다",
    "야히 파람 바스 타비 카타 하이",
    "동주 부시 조우",
    "햇 유니베르섬 씽 포르 마이",
];

const PUNCTUATIONS = ['?', '.', '...', '!'];

export {
    PROVERBS,
    PUNCTUATIONS,
}
