<template>
  <div id="app">
    <div class="background-wrapper">
      <div class="background" />
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import Amplify from 'aws-amplify';
import awsConfig from "./aws-exports";

export default {
  name: "App",
  beforeCreate() {
    // configures redirects based on dev/prod
    const isLocalhost = Boolean(window.location.hostname === "localhost");
    const [
      localRedirectSignIn,
      productionRedirectSignIn,
    ] = awsConfig.oauth.redirectSignIn.split(",");

    const [
      localRedirectSignOut,
      productionRedirectSignOut,
    ] = awsConfig.oauth.redirectSignOut.split(",");

    const updatedAwsConfig = {
      ...awsConfig,
      oauth: {
        ...awsConfig.oauth,
        redirectSignIn: isLocalhost
          ? localRedirectSignIn
          : productionRedirectSignIn,
        redirectSignOut: isLocalhost
          ? localRedirectSignOut
          : productionRedirectSignOut,
      },
    };
    Amplify.configure(updatedAwsConfig);
  }
};
</script>

<style>
@font-face {
  font-family: "bangersregular";
  src: url("./fonts/bangers-regular-webfont.woff2") format("woff2"),
    url("./fonts/bangers-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

#app {
  font-family: "bangersregular", Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  height: 100%;
}

.background-wrapper {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  opacity: 0.4;
  z-index: 0;
}

.background {
  content: "";
  background-image: url("https://media.giphy.com/media/274oCYbGZUQKUEW5rH/giphy.gif");
  background-size: 100% 100%;
  height: 100%;
  width: 100%;
}
</style>
