
class LocalStorageService {
    static PREFIX = 'sskr';

    static get(k) {
        return localStorage.getItem(`${this.PREFIX}_${k}`);
    }

    static set(k, v) {
        localStorage.setItem(`${this.PREFIX}_${k}`, v);
    }

    static del(k) {
        localStorage.removeItem(`${this.PREFIX}_${k}`);
    }
}

export default LocalStorageService;
