
class SessionStorageService {
    static PREFIX = 'sskr';

    static get(k) {
        return sessionStorage.getItem(`${this.PREFIX}_${k}`);
    }

    static set(k, v) {
        sessionStorage.setItem(`${this.PREFIX}_${k}`, v);
    }

    static del(k) {
        sessionStorage.removeItem(`${this.PREFIX}_${k}`);
    }
}

export default SessionStorageService;
