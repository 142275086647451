import SessionStorageService from './sessionStorage';

class FiltersService {
    static save(k, v) {
        SessionStorageService.set(k, JSON.stringify(v));
    }

    static get(k) {
        return JSON.parse(SessionStorageService.get(k) || '[]');
    }
}

export default FiltersService;
