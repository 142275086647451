import reject from 'lodash/reject';
import find from 'lodash/find';

import LocalStorageService from './localStorage';


class HistoryService {
    static HISTORY_KEY = 'history';
    static SAVED_KEY = 'saved';
    static NUM_HISTORY_RECORDS = 20;

    static _fetch(k) {
        return JSON.parse(LocalStorageService.get(k) || '[]');
    }

    static _push(item, k, max) {
        const old = this._fetch(k);

        if (max && old.length >= max) {
            old.pop();
        }

        old.unshift(item);

        const newItems = JSON.stringify(old);

        LocalStorageService.set(k, newItems);
    }

    static getHistory() {
        return this._fetch(this.HISTORY_KEY)
    }

    static getSaved() {
        return this._fetch(this.SAVED_KEY);
    }

    static pushHistory(historyItem) {
        this._push(historyItem, this.HISTORY_KEY, this.NUM_HISTORY_RECORDS)
    }

    static pushSaved(savedItem) {
        this.deleteHistory(savedItem);

        this._push(savedItem, this.SAVED_KEY);
    }

    static deleteSaved(item) {
        const savedItems = this.getSaved();
        const newSaved = reject(savedItems, i => i.m === item.m && i.c === item.c);

        LocalStorageService.set(this.SAVED_KEY, JSON.stringify(newSaved));
    }

    static deleteHistory(item) {
        const historyItems = this.getHistory();
        const newHistory = reject(historyItems, i => i.m === item.m && i.c === item.c);

        LocalStorageService.set(this.HISTORY_KEY, JSON.stringify(newHistory));
    }

    static isSaved(item) {
        const savedItems = this.getSaved();

        const existingItem = find(savedItems, i => i.m === item.m && i.c === item.c);
        return existingItem !== undefined;
    }
}

export default HistoryService;