<template>
  <span>{{ tweenedValue }}</span>
</template>
<script>
export default {
    props: {
      text: String,
      disableInitialScramble: {
        type: Boolean,
        default: false,
      },
      scrambleDuration: {
        type: Number,
        default: 10,
      },
      scrambleCharacterSet: {
        type: String,
        default: "ㄱㄲㄴㄷㄸㄹㅁㅂㅃㅅㅆㅇㅈㅉㅊㅋㅍㅎㅏㅓㅗㅜㅡㅣㅐㅔㅑㅕㅛㅠㅒㅖㅘㅙㅝㅞㅚㅟㅢㅌ",
      },
      scrambleSpeed: {
        type: Number,
        default: 70
      }
    },
    data() {
        return {
            queue: [],
            tweenedValue: '',
            frame: null,
            frameRequest: null,
            timeout: null,
        }
    },
    mounted() {
      if (this.disableInitialScramble) {
        this.tweenedValue = this.text;
      } else {
        this.setText(this.tweenedValue, this.text);
      }
    },
    watch: {
        text(newText, oldText)  {
            this.setText(oldText, newText);
        },
    },
//  @Watch("text")
//  public onTextChanged(newValue: string, oldValue: string) {
//  }
    methods: {
      setText(oldTextRaw, newTextRaw) {
        const oldText = oldTextRaw || '';
        const newText = newTextRaw || '';
        const length = Math.max(oldText.length, newText.length);
        this.queue = [];
        for (let i = 0; i < length; i++) {
          const from = oldText[i] || "";
          const to = newText[i] || "";
          const start = Math.floor(Math.random() * this.scrambleDuration);
          const end = start + Math.floor(Math.random() * this.scrambleDuration);
          this.queue.push({ from, to, start, end });
        }
        cancelAnimationFrame(this.frameRequest);
        clearInterval(this.timeout);
        this.frame = 0;
        this.update();
      },

      randomChar() {
        return this.scrambleCharacterSet[
          Math.floor(Math.random() * this.scrambleCharacterSet.length)
        ];
      },
      update() {
        let output = "";
        let complete = 0;
        for (let i = 0, n = this.queue.length; i < n; i++) {
          const { from, to, start, end, charTmp } = this.queue[i];
          let char = charTmp;
          if (this.frame >= end) {
            complete++;
            output += to;
          } else if (this.frame >= start) {
            if (!char || Math.random() < 0.28) {
              char = this.randomChar();
              this.queue[i].char = char;
            }
            output += char;
          } else {
            output += from;
          }
        }
        this.tweenedValue = output;
        if (complete !== this.queue.length) {
          this.timeout = setTimeout(() => {
              this.frameRequest = requestAnimationFrame(this.update)
              this.frame++;
          }, this.scrambleSpeed);
        } else {
            this.$emit('scramble-complete');
        }
      }
    }
}
</script>
<style lang="scss" scoped>
</style>
