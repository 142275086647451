const MONA_LISA = `                   #&%%###/.    ..,,*/(((/(#/(/
                 %&%%%%##(((((((((##(#%%&%%&&%####((###*
               *#((((((((//(((((#####((((%%%%##%%#####%%%%#(,,.
            .(####((//(((##(((##(/##(((#%%#%%#&&&%%%##%%###%##(/**
          ,#######//((/(###(###(/((((#######(%##%%#%%#%####%%((#(#(/(,
        *#####%((###((#&%####(#(#%(((#%##%&%%&###&&&&&%#(#####(#%(((##(#(.
      *(###%%%##&%%%#%&&%%%####%%(#%%%######%%%%%%%%##%%%#(((###(###((###(*
     /#%%%%%%%#%%%@&&&&&%%#%@&%#%%%%%&&&%%%%%#%%%#%%%###%%#%%####%(####(###/
   *###%&%%%&%&&%&&&&&&&&%%@&%&&&@@&&&&%%&%&%%&%%%&&%%%##%%#%%%%%&%%##%%#####%%*
#((#%#%%%%%&%%&&&@&&&%&&&&&&&&&@@@@&&@@&&&&%%&&%%&&@&%%&&%%%%&&&%&&&##&&%%####%%
%#%%%%&%&@&%&%&@&&&&@&&@&&&&&@@@@@&@@@&&&&&&&&%&&&@@@&@@@&&@&&@@&&@&&%%&&%%%%##%
#%%%%%%&&&&&%&@@&@&@&&@@&&@@@@@@@&&@@&&&@@&&&&&&@@@@@@@@@@@@&@@@@&@@&&&&&&&%%%%%
#%%&&&&@@&&&&@@@@&@@@@@@&&@@@@@@@@@&&&@@@&&&&@&&@&@@@@&&@@@@@@@@@&@@&&@@&&@&&%%%
#%@&&@@&&&&&@@@@@&@@@@@@@@@@@@@@@@@@@@@&&&&&&&&&&&&@@@@@@@@@@@&@@@@@@@@@@@&&@&%
%&&&&&&&&&&@@@@@@@@@@@@@@@@@@@&&&&&&&&&&&&&&%%%%&&&&&@@@@@@@@@@@@@@@@@@@@@&@@&%
%&&&&&&&@@@@@@@@@@@@@@&&&%%%%%%%%%%%%%%%%%%%%%%%%%&%&&&&&@@@@@@@@@@@@@@@@@&@@&&
%&&&&@@@@@@@@@@@@@&%%###(##%%%##################%%%%%&&&&&@@@@@@@@@@@@@@@@@@@@%
&&&&&&&@@@@@@@@&%##(((((##((###%%%%%%################%%%%%&&@@@@@@@@@@@@@@@@@&*
%/(###%&&@@@@&((%(((///(((((#((######%%%####(#######%%%%%%%%&&&@@@@@@@@@@@@@@&
/#((((##%&@@@%%%#%%%%#(((((######%%%%%############%%%%%%%&&&&&%&@@@@@@@@@@@@#
(#(/((##%&@&#(*//#(%%#(#%%%&&&@&&&%%%%#%%%#(############(##%%%%%&&@@@@@@@@@@#
((/(##%&&&&%(****/*/#(//(((############(#%%%&&&&%%#########%%%%##%&@@@@@@@@#
#//(%%%%&&%#/****/**/#((//((((((((((((((%%###%%%%%&&&&&&&&%%%%%%%#%&&@@@@@#
////(###%%#(/******///////(((((((((((###(/(/((%%###%%######%%%%##%%&&@@@@(
,////**(###(/******//////////(((((((/////////((####((((((((((##(##%&&&&*
/((*,**/////*****//****/////////((((////**///((#(((((((((//((((##%&@@(.
((#(//////******////////////((((#(//////(/((((((#(/(((((((########@@/.
/(/#%%%(/********/////((///((((((((#&&%##%%###/(##(((/((((((((###&@.
   /((%#/*******///////////(////((#######%&&&&%##(((((((((((##(##%.
    ./#/*******/////////((((/////(///((((((#####((((((((((#######
     /(**********/////(((/((((((/////(((((((#(((((((((#########(
      ,******//////////(((##%%%%#%%%########(((((((((((######((,
      .***//////////((((((######((((##%%%&&%%%################&,
       ,///*/////(//((((((((((((/((##(((###%%&&%%###########*
       .,////////((((((((((((((###%%%%%%#####%############(,
         ,///////(((((((((((((((((######%%##%%#####%#####*
          .(((((((((((((((((//(((((#(((#####%%%%%%%#####*
          .*((((((((((((((((((((((################%####*
            ,((#####(#########################%#%###/,
              (##############%##########%%%%%%%###%#.
                **###%%%%%%%%%%%%%%%%%%%%%%%%%%%%%&.
                     ./%%%%%%%%%%%%%%%%%%%%%%%%%&.
                          ,################/`;

export {MONA_LISA};
