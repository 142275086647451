<template>
    <a-button
        @click="go"
        size="large"
        type="primary"
        class="search-button"
    >
        <slot />
    </a-button>
</template>

<script>
import RandomRollService from '../services/randomRoll';
import FiltersService from '../services/filters';

export default {
    name: 'GoButton',
    props: {
        selectedMythic: Array,
        selectedChampion: Array,
    },
    methods: {
        go: function() {
            const [sicknessIdx, proverbIdx, punctuationIdx] = [...RandomRollService.rollProverbs()];

            const mythic = RandomRollService.rollMythic(this.selectedMythic);
            const champion = RandomRollService.rollChampion(this.selectedChampion);

            const strat = {
                m: mythic,
                c: champion,
                sIdx: sicknessIdx,
                prIdx: proverbIdx,
                puIdx: punctuationIdx,
            };

            FiltersService.save(this.selectedMythic, this.selectedChampion);
            this.$router.push({name: 'strat', params: strat})
        }
    }
}
</script>