const SICKNESS = [
    'MELT',
    'OMG',
    'WTF',
    '???',
    'CHALLENGER',
    'SPAM',
    'DEATH',
    'CC!?',
    'SNIPE',
    'NEW',
    'CRIT',
    'BURN',
    'NUKE',
    'SICK',
    'BANG',
    'SMH',
    'KILL',
    'BUSTED',
    'NERF',
    'JAYPLS',
    '1SHOT',
    'MASSIVE',
    'HUGE',
    'LARGE',
    'CRAZY',
    'DEAD',
    'DEADLY',
    'DAMAGE',
    'HEADSHOT',
    'GET THE CAMERA',
    'MONTAGE',
    'NASTY',
    'DISGUSTING',
    'JAILBREAK',
    'LIEGE',
    'EMPEROR',
    'KING',
    'HELLO??',
    'GAMER',
    'UNREAL',
    'ILLEGAL',
    'FRICK!',
    'REDDIT',
    'FAKER',
    'GOD',
    'DEITY',
    'APOCALYPSE',
    'OW',
    'OUCH',
    'YIKES',
    'DELETED',
    'CYA IN BASE',
    'CYA',
    'BYE',
    'GOODNIGHT',
    'BYE BYE',
    'OP',
    'IMBA',
    'UNBALANCED',
    'RIOT??',
    'RIOT PLS',
    'BROKEN',
    'FF15',
    'FF20',
    'FF',
    'INSTAKILL',
    'ALTF4',
    'wut',
    'PENTA',
    'A+',
    'S+',
    'S TIER',
    '80085',
    'DOG',
    'BONJWA',
    'BAN',
    'REMAKE',
    'INHUMANE',
    'RIP',
    'BROKEN ANKLES',
    'ONE DEAL',
    'CARRY',
    'CALL AN AMBULANCE',
    'WEE OO WEE OO',
    'FREE LP',
    'FREELO',
    'FREE',
];

export default SICKNESS;