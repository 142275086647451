import uniq from 'lodash/uniq';
import flatten from 'lodash/flatten';

//const MYTHICS = {
//    MYTHICS_FIGHTER: ["Divine Sunderer", "Eclipse", "Goredrinker",  "Stridebreaker",  "Trinity Force"],
//    MYTHICS_MARKSMAN: ["Galeforce",  "Trinity Force", "Kraken Slayer",  "Immortal Shieldbow"],
//    MYTHICS_ASSASSIN: ["Duskblade of Draktharr", "Eclipse", "Prowler's Claw"],
//    MYTHICS_MAGE: ["Hextech Rocketbelt",  "Riftmaker",  "Night Harvester",  "Liandry's Anguish",  "Luden's Tempest",  "Everfrost"],
//    MYTHICS_TANK: ["Shurelya's Battlesong",  "Locket of the Iron Solari",  "Frostfire Gauntlet", "Turbo Chemtank", "Sunfire Aegis"],
//    MYTHiCS_SUPPORT: ["Shurelya's Battlesong",  "Locket of the Iron Solari",  "Imperial Mandate",  "Moonstone Renewer"],
//};
const MYTHICS = {
    MYTHICS_AP: ["Everfrost", "Imperial Mandate", "Hextech Rocketbelt", "Liandry's Anguish", "Luden's Tempest", "Night Harvester", "Riftmaker", "Shurelya's Battlesong"].sort(),
    MYTHICS_AD: ["Divine Sunderer", "Duskblade of Draktharr", "Eclipse", "Galeforce", "Immortal Shieldbow", "Goredrinker", "Kraken Slayer", "Prowler's Claw", "Stridebreaker", "Trinity Force"].sort(),
    MYTHICS_tank: ["Frostfire Gauntlet", "Imperial Mandate", "Goredrinker", "Locket of the Iron Solari", "Moonstone Renewer", "Riftmaker", "Shurelya's Battlesong", "Stridebreaker", "Sunfire Aegis", "Turbo Chemtank", "Trinity Force", "Everfrost"].sort()
}

const MYTHICS_ALL = uniq(flatten(Object.values(MYTHICS)));
const MYTHICS_CATEGORIES = Object.keys(MYTHICS);
const MYTHICS_ANY = 'MYTHICS_ANY';

export {
    MYTHICS,
    MYTHICS_ALL,
    MYTHICS_CATEGORIES,
    MYTHICS_ANY,
}