<template>
    <ss-page-container>
        <div class="full-height vertical-flexer">
            <div class="full-logo" />
            <a-row class="full-width" :gutter="32">
                <a-col :xs="12">
                    <a-collapse class="search-panel" :active-key="1">
                        <a-collapse-panel key="1" class="search-panel-content" header="Mythic options">
                            <ss-filter-selector
                                :items="MYTHICS"
                                filter-name="mythic"
                                v-model="selectedMythic"
                            />
                        </a-collapse-panel>
                    </a-collapse>
                </a-col>
                <a-col :xs="12">
                    <a-collapse class="search-panel" :active-key="2">
                        <a-collapse-panel key="2" class="search-panel-content" header="Champion options">
                            <ss-filter-selector
                                :items="CHAMPIONS"
                                :transform-name="getChampionName"
                                filter-name="champion"
                                v-model="selectedChampion"
                            />
                        </a-collapse-panel>
                    </a-collapse>
                </a-col>
            </a-row>
            <ss-go-button
                class="go-button-big"
                :selected-mythic="selectedMythic"
                :selected-champion="selectedChampion"
            >
                Find strat
            </ss-go-button>
        </div>
    </ss-page-container>
</template>

<script>
import {MYTHICS} from '../data/mythics';
import {CHAMPIONS, getChampionObj} from '../data/champions';
import GoButton from './GoButton';
import FilterSelector from './FilterSelector';
import PageContainer from './PageContainer';

export default {
    name: 'FullPageSearch',
    components: {
        'ss-go-button': GoButton,
        'ss-page-container': PageContainer,
        'ss-filter-selector': FilterSelector
    },
    data() {
        return {
            MYTHICS,
            CHAMPIONS,
            selectedMythic: [],
            selectedChampion: [],
        };
    },
    methods: {
        getChampionName(c) {
            return getChampionObj(c).name;
        },
    }
};
</script>

<style>
.vertical-flexer {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1080px;
    margin: auto;
}

.horizontal-flexer {
    display: flex;
}

.full-logo {
  background-image: url("../assets/secret-strats-logo.png");
  height: 250px;
  width: 400px;
  background-repeat: no-repeat;
  background-size: 100%;
  display: block;
  margin-bottom: 2rem;
}

.search-panel {
    flex-grow: 1;
}

.search-panel-content .ant-collapse-content {
    max-height: 300px;
    overflow: auto;
}

.go-button-big {
    margin-top: 3rem;
    width: calc(100% - 32px);
    height: 4rem!important;
}
</style>
