<template>
  <ss-page-container>
      <a-row class="full-height" :gutter="20">
        <a-col class="full-height sidebar" :xs="8">
            <a-collapse class="strats-search-panel" :active-key="['1', '2']">
                <a-collapse-panel key="1" header="Mythic options">
                    <ss-filter-selector
                        :items="MYTHICS"
                        filter-name="mythic"
                        v-model="selectedMythic"
                    />
                </a-collapse-panel>
                <a-collapse-panel key="2" header="Champion options">
                    <ss-filter-selector
                        :items="CHAMPIONS"
                        :transform-name="getChampionName"
                        filter-name="champion"
                        v-model="selectedChampion"
                    />
                </a-collapse-panel>
            </a-collapse>
            <ss-go-button
                :selected-mythic="selectedMythic"
                :selected-champion="selectedChampion"
            >
                New strat
            </ss-go-button>
        </a-col>
        <a-col :xs="16" v-if="strat">
            <div class="strat">
                <div class="strat-title">
                    <a-popover placement="bottom">
                        <template slot="content">
                            Reroll mythic
                        </template>
                        <a-button @click="rerollMythic" type="link" class="strat-item">
                            <scramble-text :text="strat.m" />
                        </a-button>
                    </a-popover>
                    <a-popover placement="bottom">
                        <template slot="content">
                            Reroll champion
                        </template>
                        <a-button @click="rerollChampion" type="link" class="strat-item">
                            <scramble-text :text="championName" />
                        </a-button>
                    </a-popover>
                </div>
                <a-button
                    class="save-button"
                    @click="toggleSaved"
                    :icon="isSaved ? 'check' : ''"
                >
                    {{ isSaved ? 'Saved' : 'Save build' }}
                </a-button>
            </div>
            <div class="zwag">
                <img class="zwag-img" :src="mythicPortrait" />
                <span>+</span>
                <img class="zwag-img" :src="championPortrait" />
                <span>=</span>
                <span>{{ sickness }}</span>
            </div>
            <h3 class="wise-words">
                "{{ proverb }}{{ punctuation }}" - {{ koreanName }}
            </h3>
        </a-col>
      </a-row>
  </ss-page-container>
</template>

<script>
import findKey from 'lodash/findKey';

import ScrambleText from './ScrambleText';
import PageContainer from './PageContainer';
import FilterSelector from './FilterSelector';
import GoButton from './GoButton';
import {MYTHICS} from '../data/mythics';
import {CHAMPIONS, getChampionObj} from '../data/champions';
import {PROVERBS, PUNCTUATIONS} from '../data/proverbs';
import {MONA_LISA} from '../data/art';
import ITEMS from '../data/fromRiot/itemsRaw';
import SICKNESS from '../data/sickness';
import HistoryService from '../services/history';
import RandomRollService from '../services/randomRoll';

function getItemId(itemName) {
    return findKey(ITEMS.data, o => o.name === itemName);
}

export default {
  name: 'Strats',
  components: {
      'scramble-text': ScrambleText,
      'ss-page-container': PageContainer,
      'ss-filter-selector': FilterSelector,
      'ss-go-button': GoButton,
  },
  mounted() {
    console.log(MONA_LISA);

    this.setToRoutedParams(this.$route.params);
  },
  watch: {
    $route(to) {
        this.setToRoutedParams(to.params);
    },
  },
  data() {
    return {
        strat: null,
        MYTHICS,
        CHAMPIONS,
        selectedMythic: [],
        selectedChampion: [],
        isSaved: false,
    }
  },
  computed: {
    proverb: function() {
        if (!this.strat) return;

        return PROVERBS[this.strat.prIdx];
    },
    punctuation: function() {
        if (!this.strat) return;

        return PUNCTUATIONS[this.strat.puIdx];
    },
    sickness: function() {
        if (!this.strat) return;

        return SICKNESS[this.strat.sIdx];
    },
    championPortrait: function() {
        if (!this.strat) return '';

        let champName = this.strat.c;
        return `http://ddragon.leagueoflegends.com/cdn/11.7.1/img/champion/${champName}.png`;
    },
    mythicPortrait: function() {
        if (!this.strat) return '';

        const itemId = getItemId(this.strat.m);
        return `http://ddragon.leagueoflegends.com/cdn/11.7.1/img/item/${itemId}.png`;
    },
    championName: function() {
        return getChampionObj(this.strat.c).name;
    },
    koreanName: function() {
        return getChampionObj(this.strat.c).koreanName;
    },
  },
  methods: {
    setToRoutedParams(params) {
        const strat = {
            m: params.m,
            c: params.c,
            sIdx: params.sIdx,
            prIdx: params.prIdx,
            puIdx: params.puIdx,
        };

        this.strat = strat;
        this.isSaved = HistoryService.isSaved(strat);

        // Push to history service
        if (!HistoryService.isSaved(strat)) {
            HistoryService.deleteHistory(strat);
            HistoryService.pushHistory(strat);
        }
    },
    getChampionName(c) {
        return getChampionObj(c).name;
    },
    toggleSaved() {
        if (this.isSaved) {
            HistoryService.deleteSaved(this.strat);
        } else {
            HistoryService.pushSaved(this.strat);
        }
        this.isSaved = !this.isSaved;
    },
    rerollMythic() {
        const newMythic = RandomRollService.rollMythic(this.selectedMythic);
        const [sicknessIdx, proverbIdx, punctuationIdx] = [...RandomRollService.rollProverbs()];
        HistoryService.deleteHistory(this.strat);

        const newStrat = {
            ...this.strat,
            m: newMythic,
            sIdx: sicknessIdx,
            prIdx: proverbIdx,
            puIdx: punctuationIdx,
        };
        this.$router.push({name: 'strat', params: newStrat});
    },
    rerollChampion() {
        const newChampion = RandomRollService.rollChampion(this.selectedChampion);
        const [sicknessIdx, proverbIdx, punctuationIdx] = [...RandomRollService.rollProverbs()];
        HistoryService.deleteHistory(this.strat);

        const newStrat = {
            ...this.strat,
            c: newChampion,
            sIdx: sicknessIdx,
            prIdx: proverbIdx,
            puIdx: punctuationIdx,
        };

        this.$router.push({name: 'strat', params: newStrat});
    },
  }
}
</script>

<style scoped>
.strat {
    display: flex;
    justify-content: space-between;
}

.strat-item {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3rem;
    height: 3rem;
    text-decoration: underline;
    text-decoration-style: dotted;
    text-decoration-color: #888888;
    color: #000;
    padding: 0;
}

.strat-item:hover, .strat-item:focus {
    text-decoration-color: #000;
    text-decoration: underline!important;
    text-decoration-style: dotted!important;
    color: #000;
}

.save-button {
    margin-left: 20px;
}

.strat-item:not(:last-of-type) {
    margin-right: 10px;
}

.wise-words {
    margin-top: .5rem;
    font-size: 1rem;
    text-align: left;
}

.zwag {
    display: flex;
    align-items: center;
    font-size: 2rem;
    margin-top: 2rem;
}

.zwag > * {
    margin: 0 .5rem;
}

.zwag-img {
    height: 64px;
}

.sub-header {
    background: #004992
}


.sidebar {
    display: flex;
    flex-direction: column;
}

.strats-search-panel {
    flex-shrink: 1;
    overflow: auto;
}

.search-button {
    flex-shrink: 0;
}

.strat-title {
    display: flex;
    flex-shrink: 1;
    flex-grow: 1;
    overflow: hidden;
}
</style>
