import uniq from 'lodash/uniq';
import flatten from 'lodash/flatten';
import mapValues from 'lodash/mapValues';

import CHAMPIONS_RAW from './fromRiot/championsRaw';
import CHAMPIONS_KOREAN_NAMES from './championsKoreanNames';

const CHAMPIONS = {
    CHAMPIONS_top: ["Aatrox", "Camille", "Chogath", "Darius", "Fiora", "Gangplank", "Garen", "Gnar", "Gragas", "Heimerdinger", "Illaoi", "Irelia", "Jax", "Jayce", "Kayle", "Kennen", "Kled", "Malphite", "Mordekaiser", "Nasus", "Ornn", "Poppy", "Quinn", "Renekton", "Riven", "Rumble", "Sett", "Shen", "Singed", "Sion", "TahmKench", "Teemo", "Tryndamere", "Urgot", "Vayne", "Viego", "Volibear", "MonkeyKing", "Yasuo", "Yone", "Yorick"],
    CHAMPIONS_jungle: ["Amumu", "DrMundo", "Ekko", "Elise", "Evelynn", "Fiddlesticks", "Gragas", "Graves", "Hecarim", "Ivern", "JarvanIV", "Jax", "Karthus", "Kayn", "Khazix", "Kindred", "LeeSin", "Lillia", "MasterYi", "Nidalee", "Nocturne", "Nunu", "Olaf", "Poppy", "Rammus", "RekSai", "Rengar", "Sejuani", "Shaco", "Shyvana", "Skarner", "Taliyah", "Trundle", "Udyr", "Vi", "Viego", "Warwick", "XinZhao", "Zac"],
    CHAMPIONS_mid: ["Ahri", "Akali", "Anivia", "Annie", "AurelionSol", "Azir", "Cassiopeia", "Corki", "Diana", "Ekko", "Fizz", "Galio", "Heimerdinger", "Irelia", "Kassadin", "Katarina", "Leblanc", "Lissandra", "Lucian", "Lux", "Malzahar", "Neeko", "Orianna", "Pantheon", "Qiyana", "Ryze", "Sylas", "Syndra", "Talon", "TwistedFate", "Veigar", "Velkoz", "Viego", "Viktor", "Vladimir", "Xerath", "Yasuo", "Yone", "Zed", "Ziggs", "Zoe"],
    CHAMPIONS_bottom: ["Aphelios", "Ashe", "Caitlyn", "Draven", "Ezreal", "Jhin", "Jinx", "Kaisa", "Kalista", "KogMaw", "Lucian", "MissFortune", "Samira", "Senna", "Sivir", "Tristana", "Twitch", "Varus", "Vayne", "Xayah", "Yasuo"],
    CHAMPIONS_support: ["Alistar", "Bard", "Blitzcrank", "Brand", "Braum", "Janna", "Karma", "Leona", "Lulu", "Lux", "Maokai", "Morgana", "Nami", "Nautilus", "Pantheon", "Pyke", "Rakan", "Rell", "Senna", "Seraphine", "Sett", "Shaco", "Sona", "Soraka", "Swain", "Taric", "Thresh", "Velkoz", "Xerath", "Yuumi", "Zilean", "Zyra", "Zoe"]
}

const CHAMPIONS_ALL = uniq(flatten(Object.values(CHAMPIONS))).sort();
const CHAMPIONS_CATEGORIES = Object.keys(CHAMPIONS);
const CHAMPIONS_ANY = 'CHAMPIONS_ANY';

const CHAMPIONS_OBJS = mapValues(CHAMPIONS_RAW.data, o => {
    o.koreanName = CHAMPIONS_KOREAN_NAMES[o.id];
    return o;
});

function getChampionObj(championId) {
    return CHAMPIONS_OBJS[championId];
}

export {
    CHAMPIONS,
    CHAMPIONS_ALL,
    CHAMPIONS_CATEGORIES,
    CHAMPIONS_ANY,
    CHAMPIONS_OBJS,
    getChampionObj,
}
