<template>
    <div>
        <a-row>
            <a-col xs="24">
                <a-input
                    style="width: 100%;"
                    :value="queryString"
                    placeholder="Search"
                    :allowClear="true"
                    @change="onSearch"
                >
                    <a-icon slot="prefix" type="search" />
                </a-input>
            </a-col>
        </a-row>
        <div v-if="queryString">
            <a-row class="section">
                <a-col :xs="24 / numCols" v-for="n in numCols" :key="n">
                    <div v-for="item in slice(queriedItems, n)" :key="item">
                        <a-checkbox
                            :class="{underline: isCategoryItem(item)}"
                            @click="onClickItem"
                            :checked="states[item]"
                            :value="item"
                        >
                            {{ isCategoryItem(item) ? `Any ${getCategoryName(item)} ${filterName}` : item }}
                        </a-checkbox>
                    </div>
                </a-col>
            </a-row>
        </div>
        <div v-if="!queryString">
            <template v-for="(categoryItems, category) in items">
                <a-row :key="category" class="section">
                    <a-col xs="24">
                        <a-checkbox
                            class="underline"
                            @click="onClickCategory"
                            :checked="getCategoryState(category)"
                            :value="category"
                        >
                            Any {{ getCategoryName(category) }} {{ filterName }}
                        </a-checkbox>
                    </a-col>
                </a-row>
                <a-row :key="category + '_individual'">
                    <a-col :xs="24 / numCols" v-for="n in numCols" :key="n">
                        <div v-for="item in slice(categoryItems, n)" :key="item">
                            <a-checkbox
                                @click="onClickItem"
                                :checked="states[item]"
                                :value="item"
                            >
                                {{ transformName(item) }}
                            </a-checkbox>
                        </div>
                    </a-col>
                </a-row>
            </template>
        </div>
    </div>
</template>

<script>
import zipObject from 'lodash/zipObject';
import sum from 'lodash/sum';
import every from 'lodash/every';
import uniq from 'lodash/uniq';
import flatten from 'lodash/flatten';
import pickBy from 'lodash/pickBy';

import FiltersService from '../services/filters';

function fillState(items, fillWith) {
    return zipObject(items, Array(items.length).fill(fillWith));
}

export default {
    name: 'FilterSelector',
    props: {
        numCols: {
            type: Number,
            default: 3,
        },
        filterName: String,
        items: Object,
        value: Array,
        transformName: {
            type: Function,
            default: i => i,
        }
    },
    data() {
        const itemsAll = uniq(flatten(Object.values(this.items)))

        const states = fillState(itemsAll, false);
        return {
            itemsAll,
            itemCategories: Object.keys(this.items),
            states,
            queryString: '',
        };
    },
    mounted() {
        const statesSavedInSession = FiltersService.get(this.filterName);
        if (statesSavedInSession) {
            const newStates = {...this.states}
            statesSavedInSession.forEach(v => {
                newStates[v] = true;
            });

            this.states = newStates;
            this.broadcast();
        }
    },
    computed: {
        queriedItems() {
            const allElements = [
                ...this.itemCategories.map(i => `__CATEGORY__${i}`),
                ...this.itemsAll,
            ];

            const queriedItems = allElements.filter(e => e.toLowerCase().includes(this.queryString.toLowerCase()));

            return queriedItems;
        },
    },
    methods: {
        onSearch(newValue) {
            this.queryString = newValue.target.value;
        },
        isCategoryItem(item) {
            return item.startsWith('__CATEGORY__');
        },
        getCategoryState(category) {
            return every(this.items[category], m => this.states[m]);
        },
        onClickItem(e) {
            this.states[e.target.value] = !this.states[e.target.value];
            this.broadcast();
        },
        onClickCategory(e) {
            this.states = {
                ...this.states,
                ...fillState(this.items[e.target.value], e.target.checked),
            }
            this.broadcast();
        },
        broadcast() {
            const value = Object.keys(pickBy(this.states));

            FiltersService.save(this.filterName, value);
            this.$emit('input', value);
        },
        getCategoryName(category) {
            return category.replace('__CATEGORY__', '').split('_')[1];
        },
        slice(items, colNum) {
            const total = items.length;

            const basePerCol = Math.floor(total / this.numCols);
            let leftover = total % this.numCols;

            let colSizes = Array(this.numCols).fill(basePerCol)
            let i = 0;
            while (leftover) {
                colSizes[i]++;
                i++;
                leftover--;
            }

            const numBefore = sum(colSizes.slice(0, colNum - 1))
            return items.slice(numBefore, numBefore + colSizes[colNum - 1]);
        },
    }
}
</script>

<style>
.underline {
    text-decoration: underline;
}

.section {
    margin-top: 10px;
}

.isFiltered {
    color: #ccc!important;
}
</style>
