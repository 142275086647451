/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:fc615874-d437-4d66-99d4-0d38b021780c",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_hE1M1w1Rn",
    "aws_user_pools_web_client_id": "5s0fkudqvte1f8g4037sm9hrgk",
    "oauth": {
        "domain": "xedfp2xmvw08-staging.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:8080/,https://www.secretstrats.kr/",
        "redirectSignOut": "http://localhost:8080/,https://www.secretstrats.kr/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS"
};


export default awsmobile;
